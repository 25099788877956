import "./main.scss";
import Cookies from "js-cookie";

$(document).ready(() => {
  let lastScrollTop = 0;
  const $cookieBanner = $(".cookie-banner");
  const $header = $("header");
  const controlShowOffset = 100;
  if (!Cookies.get("cookieAgreed")) {
    $cookieBanner.fadeIn();
  }

  if ($(window).scrollTop() > controlShowOffset) {
    $(".controls-wrapper").addClass("visible");
  }

  let controlsOffsetTop = $(".controls-wrapper").offset()
    ? $(".controls-wrapper").offset().top
    : false;

  $(".cookie-links .agree").click(e => {
    Cookies.set("cookieAgreed", true, { expires: 30 });
    e.preventDefault();
    $cookieBanner.fadeOut();
  });

  $(".hamburger").click(() => {
    $(".hamburger").toggleClass("is-active");
    $("nav").toggleClass("active");
    $header.toggleClass("menu-open");
    $("body").toggleClass("no-scroll");
  });

  $(".wrapper-hero-copy a").click(() => {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $(".sector-list").offset().top - 20
      },
      1000
    );
  });

  $(window).resize(() => {
    $("body").addClass("resized");

    if (controlsOffsetTop) {
      controlsOffsetTop = $(".controls-wrapper").offset().top;
    }
  });

  $(window).scroll(() => {
    let st = $(window).scrollTop();

    if (st < 250 && $(window).width() > 1023) {
      $(".hero-image-2").css({ marginBottom: -st * 0.1 });
      $(".hero-image-3").css({ marginBottom: -st * 0.2 });
      $(".hero-image-4").css({ marginBottom: -st * 0.3 });
    }

    // For the controls at the bottom of the sector page.
    if (st > controlShowOffset) {
      $(".controls-wrapper").addClass("visible");
    } else {
      $(".controls-wrapper").removeClass("visible");
    }

    if (st < 1) {
      $header.removeClass("has-revealed");
    } else if (st < 70) {
      // In mobile safarai the bounce effect at the top/bottom of the page counts as a scroll which messes up the effect.
      // This makes sure that if you're near the top it doesn't hide on the bounce down.
      $header.removeClass("hidden");
    } else if (st > lastScrollTop) {
      // Scroll down
      $header.addClass("hidden");
    } else {
      // scroll up
      $header.removeClass("hidden");
      $header.addClass("has-revealed");
    }

    lastScrollTop = st;
  });

  // ScrollReveal animations
  if ($(window).width() > 1023) {
    // Home page
    ScrollReveal().reveal(".hero-home h1, .hero-home p", {
      delay: 0,
      duration: 500
    });

    ScrollReveal().reveal(".hero-image-1", {
      delay: 0,
      distance: "50px",
      duration: 1500
    });

    ScrollReveal().reveal(".hero-image-2", {
      delay: 50,
      duration: 1500,
      distance: "50px"
    });

    ScrollReveal().reveal(".hero-image-3", {
      delay: 100,
      duration: 1500,
      distance: "50px"
    });

    ScrollReveal().reveal(".hero-image-4", {
      delay: 150,
      duration: 1500,
      distance: "50px"
    });

    ScrollReveal().reveal(".sector-list-copy", {
      duration: 1500,
      distance: "150px"
    });

    ScrollReveal().reveal(".sector-item:nth-of-type(odd)", {
      duration: 1500,
      distance: "50px"
    });

    ScrollReveal().reveal(".sector-item:nth-of-type(even)", {
      duration: 1500,
      delay: 200,
      distance: "50px"
    });

    // Sectors
    ScrollReveal().reveal(".sector .hero-image");
    ScrollReveal().reveal(".sector .wrapper-hero-copy h1", {
      delay: 50
    });

    ScrollReveal().reveal(".sector .wrapper-hero-copy p", {
      delay: 100
    });

    ScrollReveal().reveal(
      ".person .pre-header, .person h2, .person .post-header",
      {
        distance: "-20px",
        duration: 2000
      }
    );

    ScrollReveal().reveal(".person-image", {
      distance: "80px",
      duration: 1500
    });

    ScrollReveal().reveal(".quote, .person-copy", {
      delay: 400,
      distance: "180px",
      duration: 800,
      easing: "ease-out"
    });

    $(".checklist-column").each(n => {
      let baseDelay = n * 300;
      $(".checklist-item").each(i => {
        ScrollReveal().reveal(
          `.checklist-column:nth-of-type(${n +
            1}) .checklist-number-copy:nth-of-type(${i + 1})`,
          {
            delay: baseDelay + i * 200,
            distance: "40px",
            easing: "ease-out"
          }
        );

        ScrollReveal().reveal(
          `.checklist-column:nth-of-type(${n +
            1}) .checklist-item:nth-of-type(${i + 1}) .checklist-number-box`,
          {
            delay: baseDelay + (i + 1) * 300,
            distance: "40px",
            easing: "ease-out"
          }
        );

        ScrollReveal().reveal(
          `.checklist-column:nth-of-type(${n +
            1}) .checklist-item:nth-of-type(${i + 1}) .checklist-number`,
          {
            delay: baseDelay + (i + 1) * 400,
            distance: "40px",
            easing: "ease-out"
          }
        );
      });
    });

    $(".step-container").each(n => {
      ScrollReveal().reveal(`.step-container:nth-of-type(${n + 1})`, {
        delay: n * 100,
        distance: "50px",
        easing: "ease-out"
      });
    });

    $(".useful-link").each(n => {
      ScrollReveal().reveal(`.useful-link:nth-of-type(${n + 1})`, {
        delay: n * 100,
        distance: "50px",
        easing: "ease-out"
      });
    });
  }

});
